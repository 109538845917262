import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation} from 'react-router-dom';
import _ from 'lodash';

function SearchResultsPage() {
  const location = useLocation();
  const { state } = location || {};
  
  const products = [
    { id: 1, name: 'SAF PORYA KAPAĞI', image: 'urun1.jpg', images: ['urun1.jpg'], productGroup: 'Dingil Poryalar', oemNo: '4304009300 4304010200', weight: '0,105 gr', quantity: '2', category: 'agirvasita' },
    { id: 2, name: 'SAF PORYA KAPAĞI', image: 'urun2.jpg', images: ['urun2.jpg'], productGroup: 'Dingil Poryalar', oemNo: '4304010200 4304010201', weight: '0,165 gr', quantity: '4', category: 'agirvasita' },
    { id: 3, name: 'SERTEL S CAM MİLİ TAMİR TAKIMI', image: 'urun3.jpg', images: ['urun3.jpg'], productGroup: 'Dingil Treyler', oemNo: '4304009300 4304010200', weight: '0,105 gr', quantity: '2', category: 'agirvasita' },
    { id: 4, name: 'SERTEL S CAM MİLİ BURCU', image: 'urun4.jpg', images: ['urun4.jpg'], productGroup: 'Dingil Treyler', oemNo: '4304010200 4304010201', weight: '0,165 gr', quantity: '4', category: 'agirvasita' },
    { id: 5, name: 'BPW PAPUÇ MAKARASI', image: 'urun5.jpg', images: ['urun5.jpg'], productGroup: 'Fren Tamir Takımı', oemNo: '980102910', weight: '0,965 gr', quantity: '2', category: 'agirvasita' },
    { id: 6, name: 'DAF FREN DİSK ŞİM TAKIMI', image: 'urun6.jpg', images: ['urun6.jpg'], productGroup: 'Disk Tamir Takımı', oemNo: '1691889S 1693978S', weight: '0,94 gr', quantity: '1', category: 'agirvasita' },
    { id: 7, name: 'VOLVO FREN DİSK ŞİM TAKIMI', image: 'urun7.jpg', images: ['urun7.jpg'], productGroup: 'Disk Tamir Takımı', oemNo: '3092224 3092224S1', weight: '0,865 gr', quantity: '1', category: 'agirvasita' },
    { id: 8, name: 'JURATEK FREN DİSK ŞİM TAKIMI', image: 'urun8.jpg', images: ['urun8.jpg', 'urun8-1.jpg'], productGroup: 'Disk Tamir Takımı', oemNo: '1310920', weight: '0,94 gr', quantity: '1', category: 'agirvasita' },
    { id: 9, name: 'DİSK SEKMANI', image: 'urun9.jpg', images: ['urun9.jpg'], productGroup: 'Fren Diski', oemNo: '3092224S1', weight: '0,865 gr', quantity: '1', category: 'agirvasita' },
    { id: 10, name: 'DİSK KELEPÇESİ', image: 'urun10.jpg', images: ['urun10.jpg'], productGroup: 'Fren Diski', oemNo: '20375549 ', weight: '0,94 gr', quantity: '1', category: 'agirvasita' },
    { id: 11, name: 'TESPİT PLAKASI', image: 'urun11.jpg', images: ['urun11.jpg'], productGroup: 'Fren Diski', oemNo: '1691889', weight: '0,865 gr', quantity: '1', category: 'agirvasita' },
    { id: 12, name: 'CİVATALI SAC BRAKET', image: 'urun12.jpg', images: ['urun12.jpg'], productGroup: 'Fren Diski', oemNo: '1693978', weight: '0,94 gr', quantity: '1', category: 'agirvasita' },
    { id: 13, name: 'CİTROEN Z ROT', image: 'urun13.jpg', images: ['urun13.jpg'], productGroup: 'CİTROEN C1 ÖN SOL/SAĞ    2005', oemNo: '5091 10 S1 509110', weight: '114 gr', quantity: '1', category: 'binek' },
    { id: 14, name: 'MERCEDES Z ROT', image: 'urun14.jpg', images: ['urun14.jpg'], productGroup: 'MERCEDES M SINIFI ARKA SOL SAĞ 1998/2005', oemNo: '1633200032', weight: '298 gr', quantity: '1', category: 'binek' },
    { id: 15, name: 'TOYOTA Z ROT', image: 'urun15.jpg', images: ['urun15.jpg'], productGroup: 'TOYOTA YARİS ÖN SOL/SAĞ', oemNo: '90948-02179 48819-52010 48817-52010', weight: '118 gr', quantity: '1', category: 'binek' },
  ];

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchByOEM, setSearchByOEM] = useState(false);

  // Initialize filteredProducts with the state from the location
  const [filteredProducts, setFilteredProducts] = useState(
    state && state.filteredProducts ? state.filteredProducts : []
  );

  const handleSearch = () => {
    const newFilteredProducts = products.filter((product) => {
      const searchTermLower = _.deburr(searchTerm.toLowerCase());
      const productNameLower = _.deburr(product.name.toLowerCase());

      if (searchByOEM) {
        return _.deburr(product.oemNo.toLowerCase()).includes(searchTermLower);
      } else {
        return productNameLower.includes(searchTermLower);
      }
    });

    console.log("Arama Terimi:", searchTerm);
    console.log("Filtrelenmiş Ürünler:", newFilteredProducts);

    setFilteredProducts(newFilteredProducts);

    if (newFilteredProducts.length > 0) {
      console.log("Navigating to /search-results");
      navigate('/search-results', { state: { filteredProducts: newFilteredProducts } });
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    console.log("Filtered Products:", filteredProducts);
  }, [filteredProducts]);

  return (
    <div>
     {/* Radio Butonlar */}
     <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
     <input
       type="radio"
       className="btn-check"
       name="btnradio"
       id="btnradio1"
       
       checked={!searchByOEM}
       onChange={() => setSearchByOEM(false)}
     />
     <label className="btn btn-outline-primary" htmlFor="btnradio1">
       ÜRÜN İSMİ
     </label>

     <input
       type="radio"
       className="btn-check"
       name="btnradio"
       id="btnradio2"
       
       checked={searchByOEM}
       onChange={() => setSearchByOEM(true)}
     />
     <label className="btn btn-outline-primary" htmlFor="btnradio2">
       OEM NO
     </label>
   </div>
   <div>
   <hr/>
   </div>
   
      {/* Arama Kutusu */}
      <div className="input-group mb-3">
      <input type="text" className="form-control" placeholder="Ürün adı veya OEM No ile ara..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)} aria-label="Ürün adı veya OEM No ile ara..." aria-describedby="basic-addon2" />
      <span className="input-group-text" id="basic-addon2">
        <button type="button" className="btn btn-primary" onClick={handleSearch}>
          ARA
        </button>
      </span>
    </div>
    

      {filteredProducts.length > 0 && (
        <div>
          <h2>ARAMA SONUÇLARI</h2>
          <div className="row">
            {filteredProducts.map((product) => (
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-4" key={product.id}>
                <div className="card h-100">
                  <Link to={`/product/${product.id}`} key={product.id}>
                    <img
                      src={`${process.env.PUBLIC_URL}/${product.image}`}
                      className="card-img-top img-fluid"
                      alt={product.name}
                      style={{ height: '300px', objectFit: 'cover' }}
                    />
                  </Link>
                  <div className="card-body">
                    <h5 className="card-title">{product.name}</h5>
                    <p className="card-text">OEM NO: {product.oemNo}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchResultsPage;
