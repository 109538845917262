import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link , useNavigate } from 'react-router-dom';
import './App.css';
import _ from 'lodash';

import About from './components/About';

import Products from './components/Products';
import ProductDetail from './components/ProductDetail';
import Contact from './components/Contact';
import Gallery from './components/Gallery';
import Catalog from './components/Catalog';
import Slider from './components/Slider';
import CustomNavbar from './components/CustomNavbar';
import SearchResultsPage from './components/SearchResultsPage'; 
import WhatsAppButton from './components/WhatsappButton';




function App() {
  const products = [
    { id: 1, name: 'SAF PORYA KAPAĞI', image: 'urun1.jpg', images: ['urun1.jpg'], productGroup: 'Dingil Poryalar', oemNo: '4304009300 4304010200', weight: '0,105 gr', quantity: '2', category: 'agirvasita' },
    { id: 2, name: 'SAF PORYA KAPAĞI', image: 'urun2.jpg', images: ['urun2.jpg'], productGroup: 'Dingil Poryalar', oemNo: '4304010200 4304010201', weight: '0,165 gr', quantity: '4', category: 'agirvasita' },
    { id: 3, name: 'YERLİ DİNGİL S CAM MİLİ TAMİR TAKIMI', image: 'urun3.jpg', images: ['urun3.jpg'], productGroup: 'Dingil Treyler', oemNo: '4304009300 4304010200', weight: '0,105 gr', quantity: '2', category: 'agirvasita' },
    { id: 4, name: 'S CAM MİLİ BURCU', image: 'urun4.jpg', images: ['urun4.jpg'], productGroup: 'Dingil Treyler', oemNo: '4304010200 4304010201', weight: '0,165 gr', quantity: '4', category: 'agirvasita' },
    { id: 5, name: 'BPW PAPUÇ MAKARASI', image: 'urun5.jpg', images: ['urun5.jpg'], productGroup: 'Fren Tamir Takımı', oemNo: '980102910', weight: '0,965 gr', quantity: '2', category: 'agirvasita' },
    { id: 6, name: 'DAF FREN DİSK ŞİM TAKIMI', image: 'urun6.jpg', images: ['urun6.jpg'], productGroup: 'Disk Tamir Takımı', oemNo: '1691889S 1693978S', weight: '0,94 gr', quantity: '1', category: 'agirvasita' },
    { id: 7, name: 'VOLVO FREN DİSK ŞİM TAKIMI', image: 'urun7.jpg', images: ['urun7.jpg'], productGroup: 'Disk Tamir Takımı', oemNo: '3092224 3092224S1', weight: '0,865 gr', quantity: '1', category: 'agirvasita' },
    { id: 8, name: 'JURATEK FREN DİSK ŞİM TAKIMI', image: 'urun8.jpg', images: ['urun8.jpg', 'urun8-1.jpg'], productGroup: 'Disk Tamir Takımı', oemNo: '1310920', weight: '0,94 gr', quantity: '1', category: 'agirvasita' },
    { id: 9, name: 'DİSK SEKMANI', image: 'urun9.jpg', images: ['urun9.jpg'], productGroup: 'Fren Diski', oemNo: '3092224S1', weight: '0,865 gr', quantity: '1', category: 'agirvasita' },
    { id: 10, name: 'DİSK KELEPÇESİ', image: 'urun10.jpg', images: ['urun10.jpg'], productGroup: 'Fren Diski', oemNo: '20375549 ', weight: '0,94 gr', quantity: '1', category: 'agirvasita' },
    { id: 11, name: 'TESPİT PLAKASI', image: 'urun11.jpg', images: ['urun11.jpg'], productGroup: 'Fren Diski', oemNo: '1691889', weight: '0,865 gr', quantity: '1', category: 'agirvasita' },
    { id: 12, name: 'CİVATALI SAC BRAKET', image: 'urun12.jpg', images: ['urun12.jpg'], productGroup: 'Fren Diski', oemNo: '1693978', weight: '0,94 gr', quantity: '1', category: 'agirvasita' },
    { id: 13, name: 'CİTROEN Z ROT', image: 'urun13.jpg', images: ['urun13.jpg'], productGroup: 'CİTROEN C1 ÖN SOL/SAĞ    2005', oemNo: '5091 10 S1 509110', weight: '114 gr', quantity: '1', category: 'binek' },
    { id: 14, name: 'MERCEDES Z ROT', image: 'urun14.jpg', images: ['urun14.jpg'], productGroup: 'MERCEDES M SINIFI ARKA SOL SAĞ 1998/2005', oemNo: '1633200032', weight: '298 gr', quantity: '1', category: 'binek' },
    { id: 15, name: 'TOYOTA Z ROT', image: 'urun15.jpg', images: ['urun15.jpg'], productGroup: 'TOYOTA YARİS ÖN SOL/SAĞ', oemNo: '90948-02179 48819-52010 48817-52010', weight: '118 gr', quantity: '1', category: 'binek' },
    { id: 16, name: 'SAF PORYA SOMUNU M72X1.5 SAĞ', image: 'urun16.jpg', images: ['urun16.jpg'], productGroup: 'DİNGİL PORYALAR', oemNo: '1011008800', weight: '0.900 gr', quantity: '1', category: 'agirvasita' },
    { id: 17, name: 'SAF PORYA SOMUNU M72X1.5 SOL', image: 'urun17.jpg', images: ['urun17.jpg'], productGroup: 'DİNGİL PORYALAR', oemNo: '1011008700', weight: '0.900 gr', quantity: '1', category: 'agirvasita' },
    { id: 18, name: 'SAF PORYA SOMUNU M75X1.5 SAĞ', image: 'urun18.jpg', images: ['urun18.jpg'], productGroup: 'DİNGİL PORYALAR', oemNo: '1011008600', weight: '0.900 gr', quantity: '1', category: 'agirvasita' },
    { id: 19, name: 'SAF PORYA SOMUNU M75X1.5 SOL', image: 'urun19.jpg', images: ['urun19.jpg'], productGroup: 'DİNGİL PORYALAR', oemNo: '1011008500', weight: '0.900 gr', quantity: '1', category: 'agirvasita' },
    { id: 20, name: 'SAF BİLYA KAFESİ 24’LÜ', image: 'urun20.jpg', images: ['urun20.jpg'], productGroup: 'DİNGİL PORYALAR', oemNo: '-', weight: '0.100 gr', quantity: '1', category: 'agirvasita' },
    { id: 21, name: 'SAF BİLYA KAFESİ 25’Lİ', image: 'urun21.jpg', images: ['urun21.jpg'], productGroup: 'DİNGİL PORYALAR', oemNo: '-', weight: '0.100 gr', quantity: '1', category: 'agirvasita' },
  ];

  return (
    <Router>
    <div>
    
      <div>
    
      <div >
      <CustomNavbar />
      
      </div>
        
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/products/*" element={<Products products={products} />}/>
          <Route path="/contact" element={<Contact />} />
          <Route path="/product/:id" element={<ProductDetail products={products} />} />
          <Route path="/products/binek" element={<Products products={products} />}/>
          <Route path="/products/agirvasita" element={<Products products={products} />}/>
          <Route path='/gallery' element={<Gallery />}/>
          <Route path='/catalog' element={<Catalog/>}/>
          <Route path="/map" component={Map} />
          <Route path="/search-results" element={<SearchResultsPage />} />
        </Routes>
              
       
      </div>
   
      <WhatsAppButton/>
      
    </div>
  </Router>
  );
}



function HomePage() {
  const [searchResultsWidth, setSearchResultsWidth] = useState(0);

  const handleResize = () => {
    const searchResultsElement = document.getElementById('searchResults');
    if (searchResultsElement) {
      setSearchResultsWidth(searchResultsElement.offsetWidth);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ position: 'relative', zIndex: 3 }}> 

        <Slider />
  <div className="searchResults" style={{ display: 'flex', justifyContent: 'center', position: 'fixed', width: '100%', top: '55%', zIndex: 3 }}>
    <div className="w-30 w-md-100" >
      <SearchResultsPage />
    </div>
  </div>
</div>

  );
}




export default App;