import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import Map from './Map';

import { faInstagram, faFacebook, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

class Contact extends Component {

  state = {
    pageTitle: 'İletişim - Ağırtorna',
  };

  componentDidMount() {
    document.title = this.state.pageTitle;
  }

  render() {
    return (
      <div className="container contact-container">
        <div className="row">
          <div className="col-6 contact-info" style={{ backgroundColor: '#3399CC', position: 'relative', borderRadius: '5px', borderStyle: 'solid', border: '1px', overflow: 'hidden' }}>
            <div style={{ marginTop: '20%', overflow: 'hidden' }}>
              <div style={{ borderStyle: 'solid', border: '1px', borderRadius: '3px', height: '50vh', backgroundColor: '#F0F8FF' }}>
                <br />
                <h3 style={{ marginLeft: '3%' }}>İletişim Bilgisi</h3>
                <p style={{ marginLeft: '3%' }}>
                  <FontAwesomeIcon icon={faMapLocationDot} style={{ color: 'black' }} />
                  Karatay Sanayi Çayeli Sokak No: 43 Selçuklu / KONYA
                </p>
                <p style={{ marginLeft: '3%' }}>
                  <FontAwesomeIcon icon={faPhone} style={{ color: 'black' }} />
                  +90 541 852 87 76
                </p>
                <p style={{ marginLeft: '3%' }}>
                  <FontAwesomeIcon icon={faEnvelope} style={{ color: 'black' }} />
                  info@agirtorna.com
                </p>
                <p style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '15%' }}>
                  <a href="https://wa.me/+905418528776" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#25D366' }} size='3x' />
                  </a>
                  <a href="https://www.instagram.com/agirtorna/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} style={{ color: '#cd486b' }} size='3x' />
                  </a>
                  <a href="https://www.facebook.com/profile.php?id=61555017758563&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} style={{ color: '#3399CC' }} size='3x' />
                  </a>
                  <a href="https://www.linkedin.com/company/a%C4%9Fir-torna/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} style={{ color: '#3399CC' }} size='3x' />
                  </a>
                </p>

              </div>
            </div>
            
          </div>
          <div className="col-6 contact-form">
          <div style={{ height: '100%', width: '100%', overflow: 'hidden', border: '3px', borderStyle: 'solid' }}>
            <Map />
          </div>
        </div>
        </div>
      </div>
    );
  }
}

export default Contact;
