import React, { Component } from 'react';
import '../App.css';

class Card extends Component {
  render() {
    const { imageSrc } = this.props;

    return (
      <div style={{ width: '100%', boxSizing: 'border-box', marginBottom: '20px' }}>
        <div
          className="card"
          style={{
            border: '1px solid #ccc',
            borderRadius: '8px',
            overflow: 'hidden',
            aspectRatio: '1 / 1',
          }}
        >
          <div style={{ paddingBottom: '100%', position: 'relative' }}>
            <img
              src={imageSrc}
              className="card-img-top img-fluid"
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '4px',
              }}
              alt="Gallery Item"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
