import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp as faWhatsappButton } from '@fortawesome/free-brands-svg-icons';

const WhatsAppButton = () => {
  const buttonStyle = {
    position: 'fixed',
    bottom: '10vh',
    right: '20px',
    backgroundColor: '#25D366', // WhatsApp rengi
    padding: '20px',
    borderRadius: '50%',
    cursor: 'pointer',
    zIndex: '999', // Z-index ayarı
    transition: 'bottom 0.3s ease', // Geçiş efekti
  };

  const iconStyle = {
    color: '#fff',
  };

  const handleClick = () => {
    window.open('https://wa.me/+905418528776', '_blank');
  };

  // Ekran genişliği 768px altında ise;
  if (window.innerWidth <= 768) {
    buttonStyle.bottom = '13vh';
    buttonStyle.right = '15%';
    buttonStyle.position = 'fixed';
    buttonStyle.padding = '10px'
  }

  return (
    <div style={buttonStyle} onClick={handleClick}>
      <FontAwesomeIcon icon={faWhatsappButton} style={iconStyle} size="3x" />
    </div>
  );
};

export default WhatsAppButton;
