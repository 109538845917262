import React, { Component } from 'react';

class About extends Component {
 
  /*constructor(props) {
    super(props);

    this.state = {
      activeSection: 'hakkimizda',
      NavbarStyle: {
        position: 'fixed',
        top: '15%',
        left: '1%',
        backgroundColor: '#3498db',
        padding: '22px',
        paddingTop:'2%',
        width:'12%',
        font:'bold' ,
        fontSize:'21px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'justify',
      },
    };
  }

  

  handleNavbarClick = (section) => {
    this.setState({ activeSection: section });

    const element = document.getElementById(section);
    if (element) {
      // Scroll to the selected section
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      console.error(`Element with ID ${section} not found`);
    }

    console.log(`Clicked on navbar item: ${section}`);
  };*/

  state = {
    pageTitle: 'Kurumsal - Ağırtorna',
  };

  componentDidMount() {
    this.setState({ pageTitle: 'Kurumsal - Ağırtorna' }, () => {
      document.title = this.state.pageTitle;
    });
  }
  
  render() {
   
    return (
      <div className="container about-container">

    {/*<div className="navbar about-navbar" style={NavbarStyle}>
          {['Hakkımızda', 'Değerlerimiz', 'Vizyonumuz', 'Misyonumuz','Kalite Politikamız'].map((section) => (
            <div
              key={section}
              className={`navbar-item ${this.state.activeSection === section.toLowerCase().replace(/ğ/g, 'g').replace(/ı/i,'i').replace(/ı/i,'i') ? 'active' : ''}`}
              onClick={() => this.handleNavbarClick(section.toLowerCase().replace(/ğ/g, 'g').replace(/ı/i,'i').replace(/ı/i,'i'))}
            >
              {section}<br/><hr/>
            </div>
          ))}
          </div>*/}

        <div className="content" style={{ marginTop: '1%' }}>
          <div className="row">
            <div className="col-12 col-md-6 mb-3">
              <img
                src='./Erakın1.jpeg'
                alt='Erakın 1'
                className="img-fluid"
                style={{ border: '2px', borderStyle: 'solid', borderRadius: '5px', width: '350px', height: '40vh' }}
              
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <img
                src='./Erakın2.jpeg'
                alt='Erakın 2'
                className="img-fluid"
                style={{ border: '2px', borderStyle: 'solid', borderRadius: '5px', width: '350px', height: '40vh' }}
              />
            </div>
          </div>


          <div className="section-container">
            <div id="hakkimizda" className={`section ${this.state.activeSection === 'hakkimizda' ? 'active' : ''}`}>
              <p>
                <strong style={{ fontSize: '25px' }}>HAKKIMIZDA</strong>
              </p>
              <p className='fw-normal'>
          1955 yılında Mehmet Polat Erakın tarafından Konya İstanbul Caddesi'nde kurulan Ağır Torna, köklü bir geçmişe
          sahiptir. 1980'li yılların başında Konya'nın önde gelen imalatçıları arasında yer almak için çeşitli makine
          yatırımları gerçekleştirilmiştir. Firmanın kurucusu olan Mehmet Polat Erakın, Konya'nın ilk tornacılarından
          biridir.
        </p>
        <p className='fw-normal'>
          2000 yılında yapılan önemli yatırımlarla birlikte Ağır Torna, ağır vasıta araçlarının yedek parça imalatına
          adım atmıştır. Bu süre zarfında kalite politikası ve hedeflerine odaklanarak Türk Endüstri Zinciri'nin
          güvenilir bir bağı olmuştur. Tamamen yerli üretim ve özverili çalışmalarıyla sektörün öncülerinden biri
          olmayı başarmıştır. Ürünlerimizi Türkiye'nin her köşesine ve dünya pazarına sunarak ülke ekonomisine katkıda
          bulunmaktan gurur duyuyoruz.
        </p>
        <p className='fw-normal'>
          Değerli müşterilerimize, olaylara profesyonel bir yaklaşım sergileyen, iş ahlakına sahip, yenilikçi ve
          özverili bir çalışma anlayışıyla kaliteli hizmet sunmaya devam ediyoruz. Geçen süre içinde, sürekli olarak
          gelişen bir firma olma hedefiyle ilerlemekteyiz. Müşterilerimizin beklentilerini karşılamak ve onlara en iyi
          hizmeti sunmak için kendimizi sürekli yeniliyoruz.
        </p>
        <p className='fw-normal'>
          Müşteri memnuniyetine büyük önem veriyor, her projeye özel çözümler üretiyoruz. Ağır Torna olarak,
          sektördeki yerimizi sağlamlaştırmak ve müşterilerimize en iyi hizmeti sunmak için çalışmaya devam ediyoruz.
          Kalite ve müşteri odaklı yaklaşımımızla, gelecekte de başarılı işlere imza atmayı hedefliyoruz.
        </p>
            </div>

            <div id="degerlerimiz" className={`section ${this.state.activeSection === 'degerlerimiz' ? 'active' : ''}`}>
              <p>
          <strong style={{fontSize: '25px'}}>DEĞERLERİMİZ</strong>
          </p>
          <div id="vizyonumuz" className={`section ${this.state.activeSection === 'vizyonumuz' ? 'active' : ''}`}>
          <p>
            <strong style={{fontSize: '15px'}}>VİZYONUMUZ</strong>
          </p>
          <p className='fw-normal'>
            "1955'ten beri güven, kalite ve uzmanlık ilkelerimizle yedek parça imalatında öncüyüz. Geleceğe yönelik
            inovasyonlarla endüstri standartlarını belirlemeyi hedefleyerek, müşteri güveni kazanmak ve sektörde liderlik
            yapmak amacındayız. Değer katma, sürekli iyileşme ve mükemmeliği benimseyerek, güven, dayanıklılık ve
            kaliteyle sektördeki varlığımızı sürdürüyoruz."
          </p>
        </div>
        <div id="misyonumuz" className={`section ${this.state.activeSection === 'misyonumuz' ? 'active' : ''}`}>
        <p>
        <strong style={{fontSize: '15px'}}>MİSYONUMUZ</strong>
      </p>
      <p className='fw-normal'>
        "Misyonumuz, kaliteyi standartlaştırarak müşteri memnuniyetini artırmak, sektörde liderlik yaparak inovasyonu
        teşvik etmek ve her gün daha iyiye ulaşmak için sürekli çaba sarf etmektir. Müşterilerimizle güçlü bir
        işbirliği kurarak, yedek parça dünyasındaki varlığımızı sürdürülebilir kılmayı ve güvenilir bir iş ortağı
        olarak bilinmeyi amaçlıyoruz."
      </p>
      </div>
            </div>

            <div id="kalite politikamiz" className={`section ${this.state.activeSection === 'kalite politikamiz' ? 'active' : ''}`}>
             <strong style={{fontSize: '25px'}}>KALİTE POLİTİKAMIZ</strong>
            <p className='fw-normal'>
              <strong>Firmamız, yedek parça sektöründe müşteri memnuniyetini ve üst düzey kalite standartlarını temel
                prensip olarak benimsemektedir. Kalite politikamız, müşterilerimize en yüksek kalitede ürünleri sunmak,
                sürekli iyileşme ve mükemmeliyeti hedeflemek üzerine odaklanmıştır.</strong>
            </p>
            <p className='fw-normal'> 
              <strong>1. Müşteri Memnuniyeti:</strong> Müşterilerimizin beklenti ve ihtiyaçlarını anlamak, onları
              karşılamak ve aşmak için çaba gösteririz. Müşteri memnuniyetini sürekli artırmak amacıyla geri bildirimlere
              açık ve duyarlı bir şekilde yaklaşırız.
            </p>
            <p className='fw-normal'>
              <strong>2. Ürün Kalitesi:</strong> Her bir ürünümüzde yüksek kalite standartlarına riayet eder, müşterilerimize
              güvenilir ve dayanıklı yedek parçalar sunarız. Üretim süreçlerimizde kalite kontrolüne ve testlere önem
              vererek ürün güvenilirliğini sağlarız.
            </p>
            <p className='fw-normal'>
              <strong>3. Sürekli İyileşme:</strong> İş süreçlerimizi sürekli gözden geçirir, iyileştirme fırsatlarını belirler
              ve çalışanlarımızın katılımını teşvik ederiz. Kalite yönetim sistemimizi sürekli optimize ederek daha etkili
              ve verimli hale getiririz.
            </p>
            <p className='fw-normal'>
              <strong>4. Çalışan Katılımı:</strong> Çalışanlarımızı iş süreçlerine aktif bir şekilde dahil eder, eğitim ve
              gelişim fırsatları sağlayarak uzmanlıklarını artırmalarını destekleriz. Ekip ruhuyla hareket ederek kaliteyi
              bir ekip çabası olarak benimseriz.
            </p>
            <p className='fw-normal'>
              <strong>5. Çevresel Sorumluluk:</strong> Üretim süreçlerimizi çevre dostu yöntemlerle uyumlu hale getirir,
              sürdürülebilirlik ilkesini benimser ve çevresel etkimizi en aza indirgemek için çaba sarf ederiz.
            </p>
            <p className='fw-normal'>
              Bu kalite politikası, firmamızın müşteri odaklı yaklaşımını, sürekli gelişmeyi ve en üst düzeyde kalite
              standartlarını koruma taahhüdümüzü yansıtmaktadır. Firmamız, bu prensipler doğrultusunda sektördeki
              liderliğini sürdürmeye kararlıdır.
            </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
