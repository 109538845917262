import React, { useEffect } from 'react';
import Card from './Card';

function Gallery() {
  useEffect(() => {
    document.title = 'Üretim Galerisi - Ağırtorna';
  }, []);

  const photos = [
    { id: 1, imageSrc: '1.jpg' },
    { id: 2, imageSrc: '2.jpg' },
    { id: 3, imageSrc: '3.jpg' },
    { id: 4, imageSrc: '4.jpg' },
    { id: 5, imageSrc: '5.jpg' },
    { id: 6, imageSrc: '6.jpg' },
    { id: 7, imageSrc: '7.jpg' },
  ];

  return (
    <div className="container">
      <div>
        <h2 className="fs-1 text-uppercase" style={{ marginTop: '2%', marginLeft: '3%' }}>
          ÜRETİM GALERİSİ
        </h2>
      </div>
      <div className="row justify-content-center">
        {photos.map((photo) => (
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" key={photo.id}>
            <Card imageSrc={photo.imageSrc} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gallery;
