import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { icon } from 'leaflet';


const Map = () => {
  const position = [37.904249, 32.498934];

  const customIcon = icon({
    iconUrl: './marker-map.jpg',
    iconSize: [35, 35],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });
  return (
    <MapContainer center={position} zoom={13} style={{ height: '300px', width: '400px' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position} icon={customIcon}>
        <Popup><FontAwesomeIcon icon={faMapMarker} color='red'/>AĞIR TORNA
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
