import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

function Slider() {
  return (
    <div className='bd-example'>
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <picture>
            <source media="(max-width: 567px)" srcSet="home1-mobile.jpg" />
            <source media="(min-width: 568px)" srcSet="home1.jpg" />
              <img src="home1.jpg" className="d-block w-100 carousel-img" alt="..." />
            </picture>
            <div className="carousel-caption d-md-block">
              <h5 className="slider-caption">69 yılı aşkın üretim tecrübesi</h5>
            </div>
          </div>
          <div className="carousel-item">
            <picture>
            <source media="(max-width: 567px)" srcSet="home2-mobile.jpg" />
            <source media="(min-width: 568px)" srcSet="home2.jpg" />
              <img src="home2.jpg" className="d-block w-100 carousel-img" alt="..." />
            </picture>
            <div className="carousel-caption d-md-block">
              <h5 className="slider-caption">Sürdürülebilir, kaliteli ürün ve kesintisiz hizmet...</h5>
            </div>
          </div>
          <div className="carousel-item">
            <picture>
              <source media="(max-width: 567px)" srcSet="home3-mobile.jpg" />
              <source media="(min-width: 568px)" srcSet="home3.jpg" />
              <img src="home3.jpg" className="d-block w-100 carousel-img" alt="..." />
            </picture>
            <div className="carousel-caption d-md-block">
              <h5 className="slider-caption">Hızlı ve güvenilir teslimat</h5>
            </div>
          </div>
          <div className="carousel-item">
            <picture>
            <source media="(max-width: 567px)" srcSet="home4.jpg" />
            <source media="(min-width: 568px)" srcSet="home4.jpg" />
              <img src="home4.jpg" className="d-block w-100 carousel-img" alt="..." />
            </picture>
            <div className="carousel-caption d-md-block">
              <h5 className="slider-caption">+30.000 Mutlu Müşteri</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slider;
