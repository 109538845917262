import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const ProductDetail = ({ products }) => {
  const { id } = useParams();
  const productId = parseInt(id);
  const product = products.find(p => p.id === productId);
  const sliderRef = useRef(null);

  const [sliderImages, setSliderImages] = useState([]);

  useEffect(() => {
    if (product) {
      document.title = `${product.name} - Ağırtorna`;
      setSliderImages(product.images);
    } else {
      document.title = 'Ürün Bulunamadı - Ağırtorna';
    }
  }, [product]);

  if (!product) {
    return <div>Ürün bulunamadı</div>;
  }

  const CustomPrevArrow = (props) => (
    <button
      className="carousel-control-prev"
      onClick={() => sliderRef.current.slickPrev()}
      aria-label="Previous"
      type="button"
    >
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    </button>
  );

  const CustomNextArrow = (props) => (
    <button
      className="carousel-control-next"
      onClick={() => sliderRef.current.slickNext()}
      aria-label="Next"
      type="button"
    >
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
    </button>
  );

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <div className="container">
      <h2 className="fs-1 text-uppercase">{product.name}</h2>
      <div className="row">
        <div className="col-md-6">
          <Slider ref={sliderRef} {...sliderSettings}>
            {sliderImages.map((image, index) => (
              <div key={index}>
                <img src={`/${image}`} alt={product.name} className="img-fluid" style={{ border: "1px", borderStyle: "solid" }} />
              </div>
            ))}
          </Slider>
        </div>
        <div className="col-md-6">
          <div className="title-block">
            <h6>Ürün Adı</h6>
            <p>{product.name}</p>
          </div>
          
          <div className="title-block">
            <h6>Ürün Grubu</h6>
            <p>{product.productGroup}</p>
          </div>
          <div className="title-block">
            <h6>OEM No</h6>
            <p>{product.oemNo}</p>
          </div>
          <div className="title-block">
            <h6>Ağırlık (kg)</h6>
            <p>{product.weight}</p>
          </div>
          <div className="title-block">
            <h6>Kutu İçi Miktar</h6>
            <p>{product.quantity}</p>
          </div>
       
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
