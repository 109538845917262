import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate, Outlet } from 'react-router-dom';
import { Navbar as BootstrapNavbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../App.css";

function CustomNavbar() {
  const [isProductDropdownOpen, setProductDropdownOpen] = useState(false);
  const [isMediaDropdownOpen, setMediaDropdownOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleSelect = () => {
    if (isMobile) {
      setShowMenu(false); // Link tıklandığında mobil görünümde menüyü kapat
      setProductDropdownOpen(false); // Ürünler dropdown'ını kapat
      setMediaDropdownOpen(false); // Medya dropdown'ını kapat
    }
  };

  const [logoStyles, setLogoStyles] = useState({
    width: '10%',
    height: 'auto',
    marginLeft: '3%',
    marginTop: '1%',
  });

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 768;
      setIsMobile(newIsMobile);
  
      if (newIsMobile) {
        setLogoStyles({
          width: '25%',
          height: 'auto',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '1%',
        });
      } else {
        setLogoStyles({
          width: '10%',
          height: 'auto',
          marginLeft: '5%',
          marginTop: '1%',
        });
      }
  
      setShowMenu(false); // Pencere boyutu değiştiğinde menüyü kapat
      setProductDropdownOpen(false); // Ürünler dropdown'ını kapat
      setMediaDropdownOpen(false); // Medya dropdown'ını kapat
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => window.removeEventListener('resize', handleResize);
  }, [setIsMobile, setLogoStyles, setProductDropdownOpen, setMediaDropdownOpen]);
  

  const navigate = useNavigate();
  const handleProductDropdownOpen = () => {
    setProductDropdownOpen(true);
  };
  
  const handleProductDropdownClose = () => {
    setProductDropdownOpen(false);
  };

  const handleProductClick = () => {
    navigate('/products');
  };

  const handleBinekClick = () => {
    navigate('/products/binek');
  };

  const handleAgirVasitaClick = () => {
    navigate('/products/agirvasita');
  };

  const handleMediaDropdownOpen = () => {
    setMediaDropdownOpen(true);
  };

  const handleMediaDropdownClose = () => {
    setMediaDropdownOpen(false);
  };

  return (
    <BootstrapNavbar
      bg="light"
      expand="lg"
      className='trn'
    >
      <Link to="/" style={{ textDecoration: 'none' }}>
        <BootstrapNavbar.Brand style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src="polat-logo-01.png"
            className="d-block"
            style={logoStyles}
            alt="Logo"
          />
        </BootstrapNavbar.Brand>
      </Link>

      <BootstrapNavbar.Toggle aria-controls="responsive-navbar-nav" id="custom-toggle" />

      <BootstrapNavbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <NavLink to="/" className="nav-link" onClick={handleSelect}>
            ANASAYFA
          </NavLink>
          <NavLink to="/about" className="nav-link" onClick={handleSelect}>
            KURUMSAL
          </NavLink>

          <NavDropdown
            title="ÜRÜNLER"
            id="product-dropdown"
            show={isProductDropdownOpen}
            onMouseOver={handleProductDropdownOpen}
            onMouseLeave={handleProductDropdownClose}
          >
            <NavLink
              to="/products/"
              className={`dropdown-item ${isProductDropdownOpen ? 'show' : ''}`}
              onClick={handleSelect}
            >
              TÜM ÜRÜNLER
            </NavLink>
            <NavLink
              to="/products/binek"
              className={`dropdown-item ${isProductDropdownOpen ? 'show' : ''}`}
              onClick={handleBinekClick}
            >
              BİNEK
            </NavLink>
            <NavLink
              to="/products/agirvasita"
              className={`dropdown-item ${isProductDropdownOpen ? 'show' : ''}`}
              onClick={handleAgirVasitaClick}
            >
              AĞIR VASITA
            </NavLink>
          </NavDropdown>

          <NavDropdown
            title="MEDYA"
            id="media-dropdown"
            show={isMediaDropdownOpen}
            onMouseOver={handleMediaDropdownOpen}
            onMouseLeave={handleMediaDropdownClose}
          >
            <NavLink to="/catalog" className="dropdown-item" onClick={handleSelect}>
              KATALOG
            </NavLink>
            <NavLink to="/gallery" className="dropdown-item" onClick={handleSelect}>
              ÜRETİM GALERİSİ
            </NavLink>
          </NavDropdown>

          <NavLink to="/contact" className="nav-link" onClick={handleSelect}>
            İLETİŞİM
          </NavLink>
        </Nav>
      </BootstrapNavbar.Collapse>

      <Outlet />
    </BootstrapNavbar>
  );
}

export default CustomNavbar;
