import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Products({ products }) {
  const location = useLocation();
  const { pathname } = location;

  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  // "/products" linkine tıklandığında "category" parametresini temizle
  const category = pathname === '/products' ? '' : pathname.replace('/products/', '');

  const filteredProducts = category
    ? products.filter((product) => product.category === category || category === 'products')
    : products;

  const chunkedProducts = chunkArray(filteredProducts, 3); // Üçer üçer sırala

  useEffect(() => {
    document.title = `Ürünler ${category ? `(${category.toUpperCase()})` : ''} - Ağırtorna`;
  }, [category]);

  return (
    <div className="container">
      <h2 className="fs-1 text-uppercase" style={{ marginTop: '2%' }}>
        ÜRÜNLER {category && `(${category.toUpperCase()})`}
      </h2>
      {chunkedProducts.map((row, rowIndex) => (
        <div className="row justify-content-center" key={rowIndex}>
          {row.map((product) => (
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-4" key={product.id}>
              <div className="card h-100">
                <Link to={`/product/${product.id}`}>
                  <img
                    src={`${process.env.PUBLIC_URL}/${product.image}`}
                    className="card-img-top img-fluid"
                    alt={product.name}
                    style={{ height: '300px', objectFit: 'cover' }}
                  />
                </Link>
                <div className="card-body">
                  <h5 className="card-title">{product.name}</h5>
                  <p className="card-text">OEM NO: {product.oemNo}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Products;
