import React, {useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import SearchResultsPage from './SearchResultsPage';


function Catalog() {
  useEffect(() => {
    document.title = 'Katalog - Ağırtorna';
  }, []);
  return (
    <div>
      <h3 className="text-center" style={{marginTop:'5%'}}>Katalog İçin Bizimle İletişime Geçin!</h3>
      <br />
      <div className="container contact-container">
      <div className="row" style={{marginTop:'5%', marginLeft:'-5%'}}>
        <div className="col-md-6">
          <img
            src="catalog.jpg"
            style={{ width: '100%', maxHeight: '50vh', borderRadius: '4px' }}
            alt="Catalog"
          />
        </div>

        <div className="col-md-6 contact-info">
          <div style={{ marginTop: '20%' }}>
            <h5>İletişim Bilgisi</h5>
            <p>
              <FontAwesomeIcon icon={faMapLocationDot} style={{ color: '#1772c7' }} />
              Karatay Sanayi Çayeli Sokak No: 43 Selçuklu / KONYA
            </p>
            <p>
              <FontAwesomeIcon icon={faPhone} style={{ color: '#1772c7' }} />
              +90 541 852 87 76
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} style={{ color: '#1772c7' }} />
              info@agirtorna.com
            </p>
          </div>
          <div style={{marginTop:'10%'}}><SearchResultsPage /></div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Catalog;
